import { useEffect, useState, VFC } from "react";

import { isEqual } from "lodash";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Grid, Text } from "theme-ui";
import { isPresent } from "ts-extras";

import { EditLabels } from "src/components/labels/edit-labels";
import { Labels } from "src/components/labels/labels";
import { Page } from "src/components/layout";
import { DeleteSourceWarning } from "src/components/modals/delete-source-warning";
import { OverageModal } from "src/components/modals/overage-modal";
import { SaveWarning } from "src/components/modals/save-warning";
import { Header, SidebarForm } from "src/components/page";
import { Permission } from "src/components/permission";
import { DisplaySlug } from "src/components/slug/display-slug";
import { SourceForm } from "src/components/sources/forms/form";
import { TestUpdatedSourceButton, TestSourceBadge, TestResult } from "src/components/sources/test-source";
import { WarehousePlanning } from "src/components/sources/warehouse-planning";
import { Warning } from "src/components/warning";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  ResourcePermissionGrant,
  useDbtSyncConfigsQuery,
  useResourceLabelsQuery,
  useUpdateSourceV2Mutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Avatar } from "src/ui/avatar";
import { SquareBadge } from "src/ui/badge";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Card } from "src/ui/card";
import { ChevronDownIcon, PlusIcon } from "src/ui/icons";
import { Link } from "src/ui/link";
import { PageSpinner } from "src/ui/loading";
import { Popout } from "src/ui/popout";
import { Tabs } from "src/ui/tabs";
import { SourceConfig, SourceTunnel, SourceBadges, SourceIcon, useSource } from "src/utils/sources";

enum Tab {
  OVERVIEW = "Overview",
  CONFIGURATION = "Configuration",
  DBT_SYNC = "dbt Sync",
}

export const Source: VFC = () => {
  const params = useParams<{ id: string }>();
  const id = params.id;
  const { user } = useUser();
  const { addToast } = useToasts();
  const [config, setConfig] = useState<SourceConfig | undefined | null>(null);
  const [tunnel, setTunnel] = useState<SourceTunnel | undefined | null>();
  const [credentialId, setCredentialId] = useState<string | undefined>();
  const [tab, setTab] = useState<Tab | undefined>();
  const [deleteSource, setDeleteSource] = useState<boolean>(false);
  const [isEditLabelModalOpen, setIsEditLabelModalOpen] = useState(false);

  const [testing, setTesting] = useState(false);
  const [testResult, setTestResult] = useState<TestResult>(TestResult.Unknown);
  const [testError, setTestError] = useState<Error | null>(null);

  const { data: source, loading: loadingSource, refetch } = useSource(id);

  const { data: sourceLabels } = useResourceLabelsQuery({ resource: "source" });
  const { data: configs, isLoading: loadingDbt } = useDbtSyncConfigsQuery(undefined, {
    select: (data) => data.dbt_sync_config,
  });

  const dbtModelsEnabled = configs?.find(({ connection_id }) => String(connection_id) === String(id));
  const labels = source?.tags ?? {};
  const labelKeys = Object.keys(labels);

  const onUpdate = () => {
    addToast(`Source updated successfully!`, {
      appearance: "success",
    });
  };

  const { isLoading: updateLoading, mutateAsync: updateSource } = useUpdateSourceV2Mutation();

  const saveName = async (name: string) => {
    if (!id) {
      return;
    }

    await updateSource({
      id,
      object: {
        name,
        updated_by: user?.id != null ? String(user?.id) : undefined,
      },
    });

    onUpdate();
  };

  const save = async () => {
    if (!id) {
      return;
    }

    let updatedConfig = config;

    if (tunnel) {
      updatedConfig = {
        ...updatedConfig,
        host: null,
        server: null,
        port: null,
      };
    }

    await updateSource({
      id,
      object: {
        updated_by: user?.id != null ? String(user?.id) : undefined,
        tunnel_id: tunnel ? tunnel.id : null,
        credential_id: credentialId,
        config: updatedConfig,
      },
    });

    refetch();
  };

  const updateLabels = async (labels: Record<string, string | number>) => {
    if (!id) {
      return;
    }

    try {
      await updateSource({
        id: id,
        object: {
          tags: labels,
        },
      });

      onUpdate();
      setIsEditLabelModalOpen(false);
    } catch (error) {
      addToast(`Label update failure. ${error.message}`, { appearance: "error", autoDismiss: false });
    }
  };

  const configChanged = !isEqual(config, source?.config);
  const tunnelChanged = !isEqual(tunnel, source?.tunnel);
  const credentialChanged = !isEqual(credentialId, source?.credential_id);
  const dirty = tunnelChanged || configChanged || credentialChanged;

  const TABS = [source?.definition?.isSampleDataSource ? Tab.OVERVIEW : Tab.CONFIGURATION];

  useEffect(() => {
    if (source?.id) {
      analytics.track("Source Details Viewed", {
        source_id: source?.id,
        source_type: source?.type,
      });
    }
  }, [source?.id]);

  useEffect(() => {
    if (!tab && source) {
      setTab(source?.definition?.isSampleDataSource ? Tab.OVERVIEW : Tab.CONFIGURATION);
    }

    setConfig(source?.config);
    setTunnel(source?.tunnel);
    setCredentialId(source?.credential_id);
  }, [source]);

  const setConfigWithDirty = (config) => {
    setConfig(config);
    setTestResult(TestResult.Unknown);
  };

  if (loadingSource || loadingDbt) {
    return <PageSpinner />;
  }

  const updatedByUsername = source?.updated_by_user?.name || source?.created_by_user?.name;

  if (!loadingSource && !source) {
    return <Warning title="Source not found" />;
  }

  return (
    <>
      <PermissionProvider permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Update] }]}>
        <Page crumbs={[{ label: "Sources", link: "/sources" }, { label: source?.name ?? "" }]} size="medium">
          <Column sx={{ width: "100%", mb: 6 }}>
            <Header
              rightToolbar={[
                !source?.definition?.disableTest ? (
                  <TestSourceBadge key={0} result={testResult} testing={testing} />
                ) : undefined,
                <Permission
                  key={1}
                  permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Delete], resource_id: id }]}
                >
                  <Button variant="secondary" onClick={() => setDeleteSource(true)}>
                    Delete
                  </Button>
                </Permission>,
              ].filter(isPresent)}
              title={source?.name ?? ""}
              onNameChange={saveName}
            />
            <Row sx={{ alignItems: "center", mt: -6 }}>
              <Row sx={{ alignItems: "center", mr: 4, pr: 4, borderRight: "small" }}>
                <SourceIcon source={source} sx={{ width: "20px" }} />
                <Text sx={{ ml: 2, fontWeight: "semi" }}>{source?.definition?.name}</Text>
                <SourceBadges source={source} />
              </Row>
              <Row sx={{ alignItems: "center" }}>
                <Text sx={{ mr: 1, color: "base.6" }}>Last updated:</Text>
                <Text sx={{ mr: 1 }}>
                  {moment(source?.updated_at || source?.created_at).calendar()}
                  {updatedByUsername && " by"}
                </Text>
                {updatedByUsername && <Avatar name={updatedByUsername} />}
              </Row>
              <Row sx={{ borderLeft: "small", ml: 4, pl: 4, alignItems: "center" }}>
                <Text sx={{ mr: 1, color: "base.7" }}>Slug:</Text>
                <DisplaySlug currentSlug={source?.slug} />
              </Row>
              {labelKeys.length > 0 ? (
                <Row sx={{ height: "100%", alignItems: "center", pl: 4, ml: 4, borderLeft: "small" }}>
                  <Popout
                    content={({ close }) => (
                      <>
                        <Labels labels={labels} sx={{ maxWidth: "200px" }} />
                        <Permission
                          permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Update], resource_id: id }]}
                        >
                          <Button
                            sx={{ mt: 4 }}
                            variant="secondary"
                            onClick={() => {
                              setIsEditLabelModalOpen(true);
                              close();
                            }}
                          >
                            Edit labels
                          </Button>
                        </Permission>
                      </>
                    )}
                    contentSx={{ p: 3, minWidth: "90px" }}
                  >
                    <Text sx={{ mr: 1 }}>Labels</Text>
                    <SquareBadge>{Object.keys(labels || {}).length}</SquareBadge>
                    <ChevronDownIcon size={16} sx={{ ml: 2 }} />
                  </Popout>
                </Row>
              ) : (
                <Row sx={{ pl: 4, ml: 4, borderLeft: "small" }}>
                  <Button
                    iconBefore={<PlusIcon color="base.5" size={14} />}
                    variant="text-secondary"
                    onClick={() => {
                      setIsEditLabelModalOpen(true);
                    }}
                  >
                    Add labels
                  </Button>
                </Row>
              )}
            </Row>
          </Column>
          <Row>
            <Column sx={{ width: "100%" }}>
              <Tabs setTab={(tab) => setTab(tab as Tab)} sx={{ mb: 8 }} tab={tab} tabs={TABS} />

              {tab === Tab.CONFIGURATION && source && (
                <Row sx={{ alignItems: "flex-start" }}>
                  <Grid gap={8} sx={{ flexGrow: 1, mr: 8 }}>
                    <Permission
                      permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Update], resource_id: id }]}
                    >
                      {source.definition?.supportsInWarehouseDiffing && id && (
                        <WarehousePlanning
                          defaultPlannerDatabase={source.plan_in_warehouse_config?.plannerDatabase}
                          editPlannerDatabase={source.definition?.supportsCrossDbReference}
                          enabled={source.plan_in_warehouse}
                          id={id}
                        />
                      )}
                    </Permission>

                    {dbtModelsEnabled && (
                      <Card size="small" sx={{ maxWidth: "500px" }}>
                        <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
                          <Text sx={{ mr: 4 }}>dbt Models is enabled</Text>
                          <Row sx={{ alignItems: "center" }}>
                            <Permission
                              permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Update], resource_id: id }]}
                            >
                              <Link sx={{ mr: 2 }} to={`/extensions/dbt-models/configuration/${id}`}>
                                <Button propagate variant="dark">
                                  Edit
                                </Button>
                              </Link>
                            </Permission>

                            <Link to={`${import.meta.env.VITE_DOCS_URL}/models/dbt-models`}>
                              <Button propagate variant="secondary">
                                Learn more
                              </Button>
                            </Link>
                          </Row>
                        </Row>
                      </Card>
                    )}

                    {source.definition && (
                      <SourceForm
                        //Cheeky way to reset child componenents to initial state after save. (EX: Reseting senstive field edit state)
                        key={`${updateLoading}`}
                        hideSecret
                        config={config}
                        credentialId={credentialId}
                        definition={source.definition}
                        error={testError}
                        isSetup={false}
                        setConfig={setConfigWithDirty}
                        setCredentialId={setCredentialId}
                        setTunnel={setTunnel}
                        source={source}
                        sourceId={id}
                        tunnel={tunnel}
                      />
                    )}
                  </Grid>
                  <SidebarForm
                    buttons={
                      <>
                        {!source.definition?.disableTest && config && id ? (
                          <Permission
                            permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Update], resource_id: id }]}
                          >
                            <TestUpdatedSourceButton
                              credentialId={credentialId}
                              newConfiguration={config}
                              sourceId={id}
                              tunnelId={tunnel?.id}
                              onError={setTestError}
                              onLoading={setTesting}
                              onResult={setTestResult}
                            />
                          </Permission>
                        ) : null}
                        <Permission
                          permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Update], resource_id: id }]}
                        >
                          <Button
                            disabled={!dirty}
                            label="Save changes"
                            loading={updateLoading}
                            sx={{ width: "100%" }}
                            onClick={save}
                          />
                        </Permission>
                      </>
                    }
                    docsUrl={source.definition?.docs ?? ""}
                    message={`Can you help me with ${source.definition?.name}? `}
                    name={source.definition?.name ?? ""}
                  />
                </Row>
              )}

              {tab === Tab.OVERVIEW && source && (
                <Row sx={{ alignItems: "flex-start" }}>
                  <Grid gap={8} sx={{ flexGrow: 1, mr: 8 }}>
                    <Text>{source.definition?.name}</Text>
                    <Text>{source.definition?.longPitch}</Text>
                  </Grid>
                  <SidebarForm docsUrl={source.definition?.docs ?? ""} name={source.definition?.name ?? ""} />
                </Row>
              )}
            </Column>
          </Row>
        </Page>
      </PermissionProvider>

      <EditLabels
        description="You can label models that have similar properties"
        existingLabelOptions={sourceLabels?.resource_tag_values}
        hint="Example keys: team, project, region, env."
        isOpen={isEditLabelModalOpen}
        labels={labels ?? {}}
        loading={updateLoading}
        saveLabel="Save"
        title="Edit labels"
        onClose={() => setIsEditLabelModalOpen(false)}
        onSave={updateLabels}
      />

      <OverageModal />

      <SaveWarning dirty={dirty} />
      {deleteSource && source && (
        <DeleteSourceWarning source={source} sourceDefinition={source.definition} onClose={() => setDeleteSource(false)} />
      )}
    </>
  );
};
