import { VFC } from "react";

import { Flex, Paragraph, Text } from "theme-ui";

import { useEntitlements } from "src/hooks/use-entitlement";
import { Row } from "src/ui/box";
import { WarningIcon } from "src/ui/icons";
import { Link } from "src/ui/link";

export const OverageContentAlert: VFC = () => {
  const { data: entitlementsData, isLoading: _loadingEntitlements } = useEntitlements(true);
  const overageText = entitlementsData.overage?.destinationOverageText; // @TODO once more overages are added, determine the text based on the active overage.
  console.log("hey");
  const messageIntercom = () => {
    if (typeof (window as any)?.Intercom !== "undefined") {
      (window as any)?.Intercom(
        "showNewMessage",
        `Hi, ${overageText.replace(/This/, "my")} I would like to learn more about upgrading.`,
      );
    }
  };

  return (
    <Flex
      sx={{
        borderRadius: 1,
        bg: "reds.0",
        border: "small",
        borderColor: "red",
        position: "relative",
        width: "100%",
        flexDirection: "column",
        mt: 4,
        p: 4,
      }}
    >
      <Row gap={3} sx={{ alignItems: "center" }}>
        <WarningIcon color="red" size={20} />
        <Text sx={{ color: "red", fontWeight: 600 }}>{overageText}</Text>
      </Row>

      <Paragraph sx={{ mt: 2 }}>
        Please{" "}
        <Link sx={{ color: "black", textDecoration: "underline" }} to="/settings/billing">
          click here
        </Link>{" "}
        to upgrade or{" "}
        <Link sx={{ color: "black", textDecoration: "underline" }} onClick={() => messageIntercom()}>
          speak with
        </Link>{" "}
        a Hightouch representative.
      </Paragraph>
    </Flex>
  );
};
