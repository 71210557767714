import { useUser } from "src/contexts/user-context";
import { SourceDefinition } from "src/graphql";
import { AnimatedBox } from "src/ui/animations";

import { SourceCategory } from "./source-category";

export const SourceCatalog = ({
  sampleDataSources,
  sourceDefinitions,
  selection,
  onSelect,
}: {
  sampleDataSources: SourceDefinition[];
  sourceDefinitions: SourceDefinition[];
  selection?: SourceDefinition;
  onSelect: (definition: SourceDefinition) => void;
}) => {
  const { featureFlags } = useUser();

  const availableSources: SourceDefinition[] = [];
  const categories = {
    all: sourceDefinitions,
  };

  for (const definition of sourceDefinitions) {
    if (
      definition.type === "hubspotLegacy" &&
      !featureFlags?.hubspot_multi_merge_rule &&
      !featureFlags?.hubspot_legacy_destination
    ) {
      continue;
    }

    const firstCategory =
      Array.isArray(definition.categories) && definition.categories.length > 0 ? definition.categories[0] : null;

    if (firstCategory) {
      categories[firstCategory.name.toLowerCase()] = [...(categories[firstCategory.name.toString()] || []), definition];
      categories[firstCategory.name.toLowerCase()]["categoryData"] = firstCategory;
    }

    availableSources.push(definition);
  }

  const categoriesOrdered = ["data systems", "spreadsheets", "other"];
  const restCategories = Object.keys(categories).filter((cat) => !categoriesOrdered.includes(cat) && cat !== "all");

  const combinedCategories = [...categoriesOrdered, ...restCategories];

  return (
    <AnimatedBox animate="visible" initial="hidden" variants={containerVariants}>
      <SourceCategory
        categoryName="Sample datasets"
        categoryShortDescription="Try setting up your first sync using one of our sample datasets."
        selection={selection}
        sources={sampleDataSources}
        onSelect={onSelect}
      />
      {combinedCategories.map((categoryName) => (
        <SourceCategory
          key={categoryName}
          categoryName={categoryName}
          categoryShortDescription={categories[categoryName]["categoryData"].shortDescription}
          selection={selection}
          sources={sourceDefinitions?.filter(({ categories }) => categories?.[0]?.name.toLowerCase() === categoryName)}
          onSelect={onSelect}
        />
      ))}
    </AnimatedBox>
  );
};

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};
