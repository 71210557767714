import { useState, useEffect, FC } from "react";

import Ajv from "ajv";
import jsonSourceMap from "json-source-map";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useToasts } from "react-toast-notifications";
import { Text } from "theme-ui";

import { Editor } from "src/components/editor/editor";
import { Permission } from "src/components/permission";
import { Settings } from "src/components/settings";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  ResourcePermissionGrant,
  useAddWorkspaceRoleMutation,
  useGetWorkspaceRolesQuery,
  useUpdateWorkspaceRoleMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Modal } from "src/ui/modal";
import { Table, TableColumn } from "src/ui/table";

type Resources =
  | "*"
  | "workspace"
  | "destination"
  | "source"
  | "model"
  | "sync"
  | "audience"
  | "audience_schema"
  | "sync_template"
  | "workspace_membership"
  | "alert";
type Actions = "*" | "read" | "update" | "create" | "delete" | "start" | "enable" | "debugger" | "preview" | "testrow";
interface Policy {
  version: string;
  policies: {
    effect: "allow" | "deny";
    actions: Actions | Actions[];
    resource: Resources | Resources[];
    conditions?: {
      [key: string]: {
        in?: string | number | boolean;
        notin?: string | number | boolean;
        greaterthan?: string | number | boolean;
        lessthan?: string | number | boolean;
        exists?: string | number | boolean;
        equals?: string | number | boolean;
      };
    };
  }[];
}

interface Role {
  id: string;
  name: string;
  permissions: Policy;
  readonly?: boolean;
}

const policyJsonSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    version: {
      type: "string",
    },
    policies: {
      items: {
        type: "object",
        properties: {
          effect: {
            type: "string",
            enum: ["allow", "deny"],
          },
          actions: {
            oneOf: [
              {
                type: "string",
                enum: ["*", "read", "update", "create", "delete", "start", "enable", "debugger", "preview", "testrow"],
              },
              {
                type: "array",
                items: {
                  type: "string",
                  enum: ["read", "update", "create", "delete", "start", "enable", "debugger", "preview", "testrow"],
                },
              },
            ],
          },
          resource: {
            oneOf: [
              {
                type: "string",
                enum: [
                  "*",
                  "workspace",
                  "destination",
                  "source",
                  "model",
                  "sync",
                  "audience",
                  "audience_schema",
                  "sync_template",
                  "workspace_membership",
                  "alert",
                ],
              },
              {
                type: "array",
                items: {
                  type: "string",
                  enum: [
                    "workspace",
                    "destination",
                    "source",
                    "model",
                    "sync",
                    "audience",
                    "audience_schema",
                    "sync_template",
                    "workspace_membership",
                    "alert",
                  ],
                },
              },
            ],
          },
          conditions: {
            type: "object",
            patternProperties: {
              "^.*$": {
                type: "object",
                patternProperties: {
                  "^(in|notin|greaterthan|lessthan|exists|equals)$": {
                    oneOf: [
                      {
                        type: "string",
                      },
                      {
                        type: "number",
                      },
                      {
                        type: "boolean",
                      },
                    ],
                  },
                },
                additionalProperties: false,
              },
            },
            additionalProperties: false,
          },
        },
        required: ["effect", "actions", "resource"],
      },

      type: "array",
    },
  },
};
const DEFAULT_POLICY: Policy = { version: "2022-04-26", policies: [{ effect: "deny", actions: "*", resource: "*" }] };

const placeholder = {
  title: "No roles",
  error: "Roles failed to load, please try again.",
};

const legacyRoles = {
  admin: '{"read":true,"admin":true,"write":true}',
  editor: '{"read":true,"write":true}',
  viewer: '{"read":true,"write":false}',
};
const newRoles: Record<"admin" | "editor" | "viewer", Policy> = {
  admin: {
    version: "2022-04-26",
    policies: [
      {
        effect: "allow",
        actions: "*",
        resource: "*",
      },
    ],
  },
  editor: {
    version: "2022-04-26",
    policies: [
      {
        effect: "allow",
        actions: "*",
        resource: [
          "destination",
          "source",
          "model",
          "sync",
          "audience",
          "audience_schema",
          "sync_template",
          "workspace_membership",
          "alert",
        ],
      },
    ],
  },
  viewer: {
    version: "2022-04-26",
    policies: [
      {
        effect: "allow",
        actions: ["read"],
        resource: [
          "destination",
          "source",
          "model",
          "sync",
          "audience",
          "audience_schema",
          "sync_template",
          "workspace_membership",
          "alert",
        ],
      },
    ],
  },
};

export const Roles = () => {
  const { workspace } = useUser();
  const { appAllowReadonlyRoles } = useFlags();

  const [editingRole, setEditingRole] = useState<Partial<Role> | undefined>();
  const [insertRole, setInsertRole] = useState<boolean>(false);

  const { isLoading, data } = useGetWorkspaceRolesQuery({ workspaceId: workspace?.id });

  const addRole = () => {
    setInsertRole(true);
    setEditingRole({ name: "Custom Role", permissions: DEFAULT_POLICY });
  };

  const editRole = (role: Role) => {
    const permissionStr = JSON.stringify(role.permissions);
    if (legacyRoles.admin === permissionStr) {
      setEditingRole({
        ...role,
        permissions: newRoles.admin,
        readonly: true,
      });
    } else if (legacyRoles.editor === permissionStr) {
      setEditingRole({
        ...role,
        permissions: newRoles.editor,
        readonly: true,
      });
    } else if (legacyRoles.viewer === permissionStr) {
      setEditingRole({
        ...role,
        permissions: newRoles.viewer,
        readonly: true,
      });
    } else {
      setEditingRole({ ...role, readonly: appAllowReadonlyRoles });
    }
  };

  const roles = data?.workspaces_by_pk?.roles;

  const roleColumns: TableColumn[] = [
    {
      name: "Role",
      key: "name",
    },
  ];

  return (
    <PermissionProvider permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update] }]}>
      <Settings route="roles">
        <Row sx={{ mb: 8, justifyContent: "space-between", alignItems: "center" }}>
          <Text sx={{ fontSize: 3, fontWeight: "semi" }}>Roles</Text>
          {appAllowReadonlyRoles ? null : (
            <Permission>
              <Button onClick={addRole}>Add Role</Button>
            </Permission>
          )}
        </Row>
        <Table
          columns={roleColumns}
          data={roles}
          loading={isLoading}
          placeholder={placeholder}
          rowHeight={55}
          onRowClick={editRole}
        />
      </Settings>

      <RoleModal
        close={() => {
          setEditingRole(undefined);
          setInsertRole(false);
        }}
        insert={insertRole}
        open={Boolean(editingRole)}
        role={editingRole}
        workspaceId={workspace?.id}
      />
    </PermissionProvider>
  );
};

function getlineNumberofChar(str: string, position: number) {
  const perLine = str.split("\n");
  let total_length = 0;
  for (const [index, value] of perLine.entries()) {
    total_length += value.length;
    if (total_length >= position) return index + 1;
  }
  return perLine.length;
}

interface RoleModalProps {
  workspaceId: string;
  role: Partial<Role> | undefined;
  insert: boolean;
  close: () => void;
  open: boolean;
}

export const RoleModal: FC<RoleModalProps> = ({ workspaceId, role, insert, close, open }) => {
  const { addToast } = useToasts();
  const [editingRole, setRole] = useState<Partial<Role> | undefined>(role);
  const { isLoading: updating, mutateAsync: updateRole } = useUpdateWorkspaceRoleMutation();
  const { isLoading: creating, mutateAsync: addRole } = useAddWorkspaceRoleMutation();
  const [policy, setPolicy] = useState<string>(
    role?.permissions ? JSON.stringify(role?.permissions, null, 2) : JSON.stringify(DEFAULT_POLICY, null, 2),
  );
  const [policyErrorLine, setPolicyErrorLine] = useState<number>();
  const checkValidPolicy = (permissions = "{}", shouldSetRole?: boolean) => {
    try {
      const parsed = JSON.parse(permissions);
      const validator = new Ajv({
        allErrors: true, // do not bail, optional
        jsonPointers: true, // totally needed for this
      });
      const valid = validator.validate(policyJsonSchema, parsed);
      if (!valid) {
        let errorMessage = "";
        const sourceMap = jsonSourceMap.parse(permissions);
        const error = validator.errors?.[0];
        if (error) {
          errorMessage += "\n\n" + validator.errorsText([error]);
          if (error.params?.["allowedValues"]) {
            errorMessage += `: ${error.params?.["allowedValues"].join(", ")}`;
          }
          const errorPointer = sourceMap.pointers[error.dataPath];
          setPolicyErrorLine(errorPointer.value.line + 1);
        }
        return errorMessage;
      } else {
        if (shouldSetRole) {
          setRole({ ...editingRole, permissions: parsed });
        }
        setPolicyErrorLine(0);
        return "";
      }
    } catch (e) {
      console.error(e);
      setPolicyErrorLine(getlineNumberofChar(permissions, parseInt(e.message.split("at position ")[1])));
      return "Error: Can not parse JSON";
    }
  };
  const [policyErrors, setPolicyErrors] = useState<string>(() => {
    return checkValidPolicy(policy, false);
  });

  const setNewPolicy = (permissions = "{}", shouldSetRole?: boolean) => {
    setPolicy(permissions);
    setPolicyErrors(checkValidPolicy(permissions, shouldSetRole));
  };

  useEffect(() => {
    setRole(role);
    setNewPolicy(JSON.stringify(role?.permissions, null, 2));
  }, [role]);

  const save = async () => {
    setNewPolicy(JSON.stringify(editingRole?.permissions, null, 2));
    if (insert) {
      await addRole({
        name: editingRole?.name ?? "",
        permissions: editingRole?.permissions,
      });
      addToast(`Workspace Role ${name} added!`, {
        appearance: "success",
      });
      analytics.track("Role Added", {
        workspace_id: workspaceId,
        name: editingRole?.name,
      });
    } else if (role?.id) {
      await updateRole({
        workspaceId,
        roleId: role?.id,
        name: editingRole?.name ?? "",
        permissions: editingRole?.permissions,
      });
      addToast(`Workspace Role ${name} updated!`, {
        appearance: "success",
      });
      analytics.track("Role Updated", {
        workspace_id: workspaceId,
        name: editingRole?.name,
      });
    }
    handleClose();
  };

  const handleClose = () => {
    close();
    setRole(undefined);
  };

  const handleChangePolicy = (value) => {
    setNewPolicy(value, true);
  };

  return (
    <Modal
      footer={
        <>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {role?.readonly ? null : (
            <Button disabled={Boolean(policyErrors) || !editingRole?.name} loading={updating || creating} onClick={save}>
              {insert ? "Add" : "Save"}
            </Button>
          )}
        </>
      }
      isOpen={open}
      sx={{ maxWidth: "441px", width: "100%" }}
      title={`Manage Role: ${role?.name}`}
      onClose={handleClose}
    >
      <>
        <Field label="Name">
          <Input
            disabled={editingRole?.readonly}
            placeholder="Enter a name..."
            readOnly={editingRole?.readonly}
            value={editingRole?.name}
            onChange={(name) => {
              setRole({ ...(editingRole || {}), name });
            }}
          />
        </Field>
        <Field label="Policy">
          <Editor
            highlightErroredLine={policyErrorLine}
            language="json"
            placeholder="Enter a policy..."
            readOnly={editingRole?.readonly}
            value={policy}
            onChange={handleChangePolicy}
          />
          {policyErrors}
        </Field>
      </>
    </Modal>
  );
};
