import { VFC } from "react";

import { Text, ThemeUIStyleObject, Message, Flex } from "theme-ui";

import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { ShieldIcon, XIcon } from "src/ui/icons";
import { Link } from "src/ui/link";

import { colors } from "../../../../design/colors";

type Props = {
  sx?: ThemeUIStyleObject;
  onClose: () => void;
  onContactClick: () => void;
};

export const PermissionsMessage: VFC<Readonly<Props>> = ({ sx = {}, onClose, onContactClick }) => (
  <Message
    sx={{
      width: "unset",
      maxWidth: "unset",
      bg: "base.1",
      borderRadius: 1,
      p: 4,
      border: `1px solid ${colors.base[2]}`,
      mb: 8,
      ...sx,
    }}
  >
    <Flex sx={{ alignItems: "center", justifyContent: "space-between", gap: 3 }}>
      <Row sx={{ alignItems: "center" }}>
        <ShieldIcon color="base.6" />
        <Text color="base.6" ml={2} sx={{ fontSize: "13px" }}>
          For more granular permissions,{"  "}
          <Link onClick={onContactClick}>click here</Link>
          {"  "}
          to chat with us about our advanced Access Management features.
        </Text>
      </Row>
      <Button
        aria-label="Dismiss Message"
        iconBefore={<XIcon size={16} />}
        sx={{
          alignSelf: "flex-start",
          p: 0,
          height: "20px",
          width: "20px",
          border: "none",
          color: "purple",
          ":hover > div": {
            backgroundColor: "base.2",
            borderRadius: "6px",
            height: "20px",
            width: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          svg: {
            fill: "base.5",
          },
        }}
        variant="secondary"
        onClick={onClose}
      />
    </Flex>
  </Message>
);
