import { useFlags } from "launchdarkly-react-client-sdk";

import { useUser } from "src/contexts/user-context";
import { useBillingOrganizationQuery, useOrganizationDestinationTypesQuery } from "src/graphql";

export const freeDestinations = [
  "airtable",
  "googlesheets",
  "googleSheetsSA",
  "slack",
  "teams",
  "mattermost",
  "salesforceSandbox",
];
export const premiumDestinations = ["sfmc", "netsuite"];

const getBillingType = (billing_type: string) => {
  if (billing_type.startsWith("facebook")) {
    return "facebook";
  }
  if (billing_type === "googleCampaignManager") {
    return "google";
  }
  if (billing_type.startsWith("hubspot")) {
    return "hubspot";
  }
  if (billing_type.startsWith("sfmc")) {
    return "sfmc";
  }
  return billing_type;
};

interface Entitlements {
  destinations: number;
  preimumDestinations: number;
  audiences: boolean;
  externalStorage: boolean;
}

export const useEntitlements = (enabled: boolean) => {
  const { workspace } = useUser();
  const { appSsLockoutOverage } = useFlags();
  const { audiencesEnabled, externalStorageEnabled } = useFlags();
  const { data: orgData, isLoading: loadingOrg } = useBillingOrganizationQuery({}, { enabled });
  const { data: organizationDestinationType, isLoading: loadingDestinationTypes } = useOrganizationDestinationTypesQuery(
    {},
    { enabled },
  );

  const organization = orgData?.getBillingOrganization;
  const entitlements: Entitlements = {
    destinations: 1,
    preimumDestinations: 0,
    audiences: false,
    externalStorage: false,
    ...organization?.plan?.entitlements,
    ...organization?.entitlements,
  };
  entitlements.audiences = entitlements.audiences || audiencesEnabled;
  entitlements.externalStorage = entitlements.externalStorage || externalStorageEnabled;

  let billableDistinations = 0;
  let freeDistinations = 0;
  let totalDestinations = 0;
  let preimumDestinations = 0;
  const billedDestinations: string[] = [];
  organizationDestinationType?.getOrganizationDestinationTypes.forEach((dest) => {
    if (!dest) {
      return;
    }

    const billing_type = getBillingType(dest.type);

    if (freeDestinations.includes(billing_type)) {
      freeDistinations += 1;
    } else if (!billedDestinations.includes(billing_type)) {
      billedDestinations.push(billing_type);
      billableDistinations += 1;
    }
    if (premiumDestinations.includes(billing_type)) {
      preimumDestinations += 1;
    }
    totalDestinations += 1;
  });

  const destinationOverage =
    (billableDistinations > entitlements?.destinations && entitlements?.destinations !== -1) || appSsLockoutOverage;
  const premiumOverage = preimumDestinations > entitlements?.preimumDestinations;
  const destinationOverageText = "This workspace has exceeded the number of destinations allowed on its current plan.";

  const getDayDiff = (startDate: Date, endDate: Date): number => {
    const msInDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs(Number(endDate) - Number(startDate)) / msInDay);
  };

  const isOverageExpired = (firstOverageDate: Date | null) => {
    if (firstOverageDate) {
      const today = new Date();
      return getDayDiff(firstOverageDate, today) > 30;
    }
    return false;
  };

  const overage = destinationOverage || premiumOverage;
  return {
    isLoading: loadingOrg || loadingDestinationTypes,
    data: {
      organization,
      organizationDestinationType,
      overage: {
        destinationOverage,
        premiumOverage,
        overage,
        firstOverageDate: workspace?.first_overage_date,
        overageLockout: appSsLockoutOverage || isOverageExpired(workspace?.first_overage_date),
        destinationOverageText,
      },
      entitlements,
      destinations: {
        billableDistinations,
        freeDistinations,
        totalDestinations,
        preimumDestinations,
      },
    },
  };
};
